import { BarceloComponent } from "barcelo-components";

const MyBarceloComponents = {
  mybOpinion: BarceloComponent({ selector: ".cmp-bhg-mybOpinion", path: "mybarcelo/myb-opinions/js/c-myb-opinions" }),
  mybTriggerBanner: BarceloComponent({ selector: [".myb-trigger-banner-init-JS", ".mod--compact-carousel"], path: "mybarcelo/myb-trigger-banner/js/c-myb-trigger-banner" }),
  mybLoyaltyHero: BarceloComponent({ selector: [".cmp-l-hero", ".mod--compact-carousel"], path: "mybarcelo/reserved/myb-loyalty-hero/js/myb-loyalty-hero" }),
  mybLoyaltyInfo: BarceloComponent({ selector: [".cmp-l-info", ".mod--compact-carousel"], path: "mybarcelo/reserved/myb-loyalty-info/js/myb-loyalty-info" }),
  // mybBanner: BarceloComponent({ selector: '.c-myb-banner-JS', path: 'mybarcelo/myb-banner/js/myb-banner'})
};

export default MyBarceloComponents;
