const fastbookingCommonProps = {
  $container: null,
  isHotel: null,
  hiddenClass: "hidden",
  disabledPrices: false,
  enabledDots: false,
  enabledRounded: false,
  url: "",
  test: "test",
  attributes: {
    localStorage: "barcelo_last_searches",
  },
  lastSearchData: [],
  orderType: "ranking",
  selectors: {
    buttonSearch: "search-JS",
    buttonBooking: "booking-JS",
    buttonRoom: "booking-button-JS",
    buttonPromo: "promo-button-JS",
    addFlight: "add-flight-JS",
    open: "open-fastbooking-JS",
    close: "close-fastbooking-JS",
    closePopover: "close-JS",
    hotelButton: "hotel-button-JS",
    fastBookingNode: ".fastbooking-JS",
    allLabelClass: "all-label-JS",
    headHotelSelector: "head-hotel-info-JS",
  },
  parentFb: null,
};

const calendarCommonProps = {
  data: {
    startDate: null,
    endData: null,
  },
  dataOffer: null,
  hideDeleteHotelOption: false,
  $parentDestination: null,
  activeDateClass: "mod--active-delete",
  location: "",
  disponibility: true,
  dataDest: null,
  $startDate: "",
  obj: {},
  updateOnlyCalendar: false,
  calendarRelatedSelector: "c-fastbooking__calendar-hotels-related-JS",
  evtchangeRangeDate: "FBchangeRangeDate",
  $container: null,
  dataMessage: {},
};

const destinationCommonProps = {
  used: false,
  data: null,
  $destinationInput: null,
  activeFlight: false,
  showTooltip: false,
  lastLabel: null,
  url: null,
};

const discountCommonProps = {
  data: {},
};

const roomsCommonProps = {
  valueAdults: 0,
  valueChildren: 0,
  data: {
    rooms: 1,
    adults: [2],
    children: [],
  },
  $childrenNumberSelector: null,
  $adultsNumberSelector: null,
};

window.bcl.c.fastbooking = typeof window.bcl.c.fastbooking !== "undefined" ? window.bcl.c.fastbooking : {};
window.bcl.c.fastbooking.commonProps = typeof window.bcl.c.fastbooking.commonProps !== "undefined" ? window.bcl.c.fastbooking.commonProps : {};

window.bcl.c.fastbooking.commonProps = {
  fastbooking: { ...fastbookingCommonProps },
  calendar: { ...calendarCommonProps },
  destination: { ...destinationCommonProps },
  rooms: { ...roomsCommonProps },
  discount: { ...discountCommonProps },
};
