bcl.contextHub = {
  props: {
    nameSessionStorage: "contextHub.",
    nameProfile: "profile",
    nameFav: "favorites",
    pending: "pending",
    url: "/bin/barcelo/loadProfile.json",
    ttl: 10 * 60 * 1000,
    stores: [],
    storesPending: [],
    lasukmybCookie: "lasukmyb",
    lasukmybErrorCookie: "lasukmyb-error",
    loginError: ["inactive-user", "not-userid", "not-useremail", "api-error", "generic-error"],
  },

  init: function () {
    const store = bcl.contextHub.get(bcl.contextHub.props.nameProfile);
    const maxRetries = 5;
    const attempt = 0;

    bcl.contextHub.makeRequest(store, attempt, maxRetries);
  },

  makeRequest: function (store, attempt, maxRetries) {
    if (!store) {
      if (!bcl.contextHub.validLoginButton()) {
        bcl.contextHub.fixLoginButton();
      }
      bcl.ajax.postRequest({
        data: {},
        url: bcl.contextHub.props.url,
        callback: function (response) {
          attempt++;
          const itemCallback = { store: bcl.contextHub.props.nameProfile };

          if (response?.response?.length > 0) {
            console.log("Profile retrieved successfully.");
            bcl.contextHub.getRequest(response, itemCallback);
          } else if (attempt < maxRetries) {
            console.warn(`Attempt ${attempt} failed, retrying...`);

            setTimeout(() => {
              bcl.contextHub.makeRequest(store, attempt, maxRetries);
            }, 1000);
          } else {
            console.error("Failed to retrieve the profile after", maxRetries, "attempts.");
          }
        },
        itemCallback: { store: bcl.contextHub.props.nameProfile },
      });
    } else {
      bcl.contextHub.executeAllPending(bcl.contextHub.props.nameProfile);
    }
  },

  validLoginButton: function () {
    const cookieName = "mybLoginCleanv3";
    const actualDate = new Date();

    const cookieValue = document.cookie
      .split(";")
      .map((c) => c.trim())
      .find((c) => c.startsWith(cookieName + "="));

    if (cookieValue) {
      const expiration = new Date(decodeURIComponent(cookieValue.split("=")[1]));
      return !isNaN(expiration.getTime()) && expiration > actualDate;
    }
    return false;
  },

  fixLoginButton: function () {
    localStorage.clear();
    sessionStorage.clear();

    document.cookie.split(";").forEach((cookie) => {
      const [name] = cookie.split("=").map((c) => c.trim());
      document.cookie = `${name}=; Max-Age=0; path=/;`;
    });

    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 6);

    document.cookie = `mybLoginCleanv3=true; expires=${expirationDate.toUTCString()}; path=/`;
  },

  getRequest: function (res, obj) {
    if (!res.response) {
      return;
    }

    const store = {
      ready: true,
      expiry: Date.now() + bcl.contextHub.props.ttl,
      user: JSON.parse(res.response),
      props: bcl.contextHub.parseResponse(res.getResponseHeader("X-Akamai-Edgescape") || ""),
    };

    sessionStorage.setItem(bcl.contextHub.props.nameSessionStorage + obj.store, JSON.stringify(store));
    bcl.contextHub.props.stores[obj.store] = store;
    bcl.contextHub.executeAllPending(obj.store);
  },

  parseResponse: function (akamai) {
    return akamai.split(",").reduce((output, pair) => {
      const [key, value] = pair.split("=");
      if (key && value) {
        output[key] = value;
      }
      return output;
    }, {});
  },

  callWhenReady: function (func, name) {
    if (typeof func === "function") {
      const obj = bcl.contextHub.get(name);
      if (obj?.ready) {
        func();
      } else {
        bcl.contextHub.addPending(name, func);
      }
      return true;
    }
    return false;
  },

  isEnabled: function () {
    return true;
  },

  get: function (name) {
    let store = bcl.contextHub.props.stores[name] || sessionStorage.getItem(bcl.contextHub.props.nameSessionStorage + name);

    if (store) {
      store = typeof store === "string" ? JSON.parse(store) : store;
      const now = Date.now();
      if (!store.expiry || store.expiry < now) {
        store = null;
        delete bcl.contextHub.props.stores[name];
        sessionStorage.removeItem(bcl.contextHub.props.nameSessionStorage + name);
      } else {
        bcl.contextHub.props.stores[name] = store;
      }
    }
    return store;
  },

  getItem: function (store, name) {
    const storeObj = typeof store === "string" ? bcl.contextHub.get(store) : store;
    return storeObj?.props?.[name];
  },

  getPending: function (storeName) {
    return (bcl.contextHub.props.storesPending[storeName] ||= []);
  },

  addPending: function (store, func) {
    bcl.contextHub.getPending(store).push(func);
  },

  executeAllPending: function (store) {
    const pending = bcl.contextHub.getPending(store);
    while (pending.length > 0) {
      const item = pending.shift();
      item();
    }
  },
};
