import axios from "axios";

bcl.ajax = {
  async getRequest(item, url, callback, itemCallback, setHeader, errorCallback) {
    try {
      const config = setHeader ? { headers: { "X-Requested-With": "XMLHttpRequest" } } : {};
      const response = await axios.get(url, config);

      if (response.status >= 200 && response.status < 400) {
        if (item) {
          item.innerHTML = response.data;
        }

        if (callback) {
          if (typeof callback === "string") {
            bcl.u.executeFunctionByName(callback, window, response.request, item, itemCallback);
          } else if (typeof callback === "function") {
            callback(response.request, itemCallback);
          }
        }

        if (item) {
          document.dispatchEvent(new CustomEvent("ajax-executed", { detail: item }));
        }
      } else {
        bcl.u.removeBhgLoading();
        if (errorCallback) {
          errorCallback(itemCallback, response.request);
        }
      }
    } catch (error) {
      console.error("Axios GET error in url:", url);
      console.error("GET error details:", error);
    }
  },

  async postRequest(obj) {
    const { data = {}, url, contentType, errorCallback } = obj;
    if (!url) {
      return;
    }

    const isJson = contentType === "json";
    const config = {
      headers: { "Content-Type": isJson ? "application/json" : "application/x-www-form-urlencoded" },
    };
    const formattedData = isJson ? JSON.stringify(data) : bcl.u.serializeObject(data);

    try {
      const response = await axios.post(url, formattedData, config);
      this.onLoad(obj, response.request);
    } catch (error) {
      console.error("Axios POST error in url:", url);
      console.error("POST error details:", error);
      if (errorCallback) {
        errorCallback(obj.itemCallback);
      }
    }
  },

  async putRequest(obj) {
    const { data, url, contentType } = obj;
    if (!url) {
      return;
    }

    const isBearer = contentType.includes("Bearer");
    const config = isBearer ? { headers: { "Content-Type": "application/json", Authorization: contentType } } : {};

    try {
      const response = await axios.put(url, isBearer ? JSON.stringify(data) : data, config);
      this.onLoad(obj, response.request);
    } catch (error) {
      console.error("Axios PUT error in url:", url);
      console.error("PUT error details:", error);
    }
  },

  onLoad(obj, request) {
    const { callback, itemCallback, errorCallback } = obj;

    if (request.status >= 200 && request.status < 400 && callback) {
      if (typeof callback === "string") {
        bcl.u.executeFunctionByName(callback, window, request, itemCallback);
      } else if (typeof callback === "function") {
        callback(request, itemCallback);
      }
    }

    if (request.status >= 500 && typeof errorCallback === "function") {
      errorCallback(request, itemCallback);
    }
  },
};
