import { BarceloComponent } from "barcelo-components";

const HotelComponents = {
  dorintHotelCards: BarceloComponent({
    selector: ".c-dorint-hotel-cards-JS",
    path: "hotel/dorint-hotel-cards/js/dorint-hotel-cards",
  }),
  hotelRelated: BarceloComponent({
    selector: ".c-hotel-related-JS",
    path: "hotel/hotel-related/js/hotel-related",
  }),
  weather: BarceloComponent({
    selector: ".c-weather",
    path: "hotel/hotel-weather/js/c-hotel-weather",
  }),
  hotelHighlights: BarceloComponent({
    selector: ".js-c-hotel-highlights",
    path: "hotel/hotel-highlights/js/hotel-highlights",
  }),
};

export default HotelComponents;
