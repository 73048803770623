export function mutateBarceloComponents({ component, name, belongsTo }) {
  if (belongsTo) {
    const target = bcl.c[belongsTo] || {};
    bcl.c = {
      ...bcl.c,
      [belongsTo]: {
        ...target,
        [name]: component,
      },
    };
  } else {
    const target = bcl.c[name] || {};
    bcl.c = {
      ...bcl.c,
      [name]: {
        ...target,
        ...component,
      },
    };
  }
}

export const isPresent = (selector, root = document) => {
  if (Array.isArray(selector)) {
    return selector.some((item) => root.querySelector(item));
  }
  return !!root.querySelector(selector);
};

export const dynamicImport = async ({ customName, name, belongsTo, component, initMethod, skipInit, root = document }) => {
  const componentName = customName || name;
  const importedComponent = component.default;

  mutateBarceloComponents({ component: importedComponent, name: componentName, belongsTo });

  if (!skipInit && importedComponent[initMethod]) {
    if (root !== document && importedComponent[initMethod].length > 0) {
      importedComponent[initMethod](root);
    } else {
      importedComponent[initMethod]();
    }
  }

  return importedComponent;
};
