const loadedComponents = {};

const loadComponent = async (name, component, options, retries = 50) => {
  if (!component || (options?.ignoreIsPresent !== true && !component.isPresent(options))) {
    return false;
  }

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const importedComponent = await component.importComponent({ name, root: options?.root });
      // console.info(`Imported ${name} component (attempt ${attempt})`);
      return !!importedComponent;
    } catch (error) {
      // console.info(`Error importing ${name} component (attempt ${attempt}):`);
      // console.error(error);

      if (attempt === retries) {
        console.error(`Error importing ${name} component`, error);
        return false;
      }
      await new Promise((res) => setTimeout(res, 100 * attempt)); // Exponential backoff
    }
  }
};

const BarceloComponents = {
  init: async (components, options = { root: document }) => {
    const loadPromises = Object.entries(components).map(async ([name, component]) => {
      const isLoaded = await loadComponent(name, component, options);
      loadedComponents[name] = isLoaded;
    });

    await Promise.all(loadPromises);

    bcl.c.manifest = { ...bcl.c.manifest, ...loadedComponents };
  },
};

export default BarceloComponents;
