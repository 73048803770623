const debug = true;

import "../../base/js/init.js";
import "../../base/js/utils/preCommonUtils.js";
import "../../../components/bhg/image.js";
import "../../base/js/utils/ajaxUtils.js";
import "../../base/js/utils/commonUtils.js";
import "../../base/js/shared/loading.js";
import "../../base/js/utils/customContextHubUtils.js";
import "../../base/js/utils/lastjs.js";

// Fastbooking
import "../../../components/barcelo/structure/fastbooking/js/commonFastbookingProps.js";
import "../../../components/barcelo/structure/fastbooking/js/c-fastbooking";

const loadNecesaryScripts = async function () {
  // Analytics
  if (document.cookie.includes("webVitals=true;")) {
    await import("../../base/js/utils/webVitals.js");
    if (debug) {
      console.log("Performance: Web Vitals loaded");
    }
  }
};

// Dynamic imports
if (document.readyState !== "loading") {
  loadNecesaryScripts();
} else {
  document.addEventListener("DOMContentLoaded", loadNecesaryScripts, true);
}
