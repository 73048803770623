if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/etc.clientlibs/barcelo/clientlibs/clientlib-serviceworker.min.js").catch(function (err) {
    console.error(err);
  });
}

bcl.preInit.push(function () {
  bcl.u.docReady(function () {
    bcl.u.utag.enableListener("profile");
    bcl.contextHub.callWhenReady(function () {
      const profile = bcl.contextHub.get("profile");
      if (profile) {
        const city = bcl.contextHub.getItem(profile, "city"),
          countryCode = bcl.contextHub.getItem(profile, "country_code"),
          internalIP = bcl.contextHub.getItem(profile, "internalIP"),
          data = {
            // eslint-disable-next-line camelcase
            user_country_name: countryCode && countryCode.toLowerCase(),
            // eslint-disable-next-line camelcase
            user_city_name: city && city.toLowerCase(),
            // eslint-disable-next-line camelcase
            user_zipcode: bcl.contextHub.getItem(profile, "zip"),
            // eslint-disable-next-line camelcase
            user_latitude: bcl.contextHub.getItem(profile, "lat"),
            // eslint-disable-next-line camelcase
            user_longitude: bcl.contextHub.getItem(profile, "long"),
            // eslint-disable-next-line camelcase
            user_internal: internalIP ? "1" : "0",
          };
        bcl.u.utag.add(data);
      }
      bcl.u.utag.removeListener("profile");
    }, "profile");

    bcl.u.utag.props.lastReady = true;
    bcl.u.utag.init();
  }, true);
});
bcl.u.load = true;
window.addEventListener("bcl-base-components-loaded", bcl.u.preInit, true);

bcl.contextHub.init();
