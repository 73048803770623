//----------------------------------------------------------------//

bcl.u.breakMobile = 1024;
bcl.u.breakTablet = 605;
bcl.u.breakMobilePlus = 1025;

bcl.u.mobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },

  webView: function () {
    return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
  },

  isMobile: function () {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= bcl.u.breakMobile;
  },

  isTablet: function () {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < bcl.u.breakMobilePlus && Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= bcl.u.breakTablet;
  },
};

//----------------------------------------------------------------//
